<template>
  <div
    class="image"
    :class="$vuetify.theme.isDark ? 'imageDark' : ''"
    :style="
      $vuetify.breakpoint.xsOnly ? 'transform: scale(0.75);margin: -46px;' : ''
    "
  >
    <!-- الفك العلوي -->
    <!-- <div class="">
      <ul>
        <li class="lime-text">Diagnose</li>
      </ul>
    </div> -->
    <v-menu
      v-for="(teeth, ind) in tooths"
      :key="ind"
      open-on-hover
      top
      offset-y
      class="pa-3"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-img
          v-bind="attrs"
          v-on="on"
          :transition="teeth.transition"
          @click="clicked($event, teeth.ref)"
          contain
          :ref="teeth.ref"
          :style="teeth.style"
          :src="teeth.src"
        >
        </v-img>
      </template>
      <div
        class="pa-2"
        style="background: #4f89b2b8"
        v-if="toothModel[ind].length > 0"
      >
        <ul>
          <li
            v-for="(item, i) in toothModel[ind]"
            :key="i"
            :class="
              item.isDiagnose ? 'lime--text text--accent-3' : 'white--text '
            "
            v-text="item.treatmentName"
          ></li>
        </ul>
      </div>

      <!-- <v-list shaped disabled v-if="toothModel[ind].length > 0">

        <v-list-item-group multiple>
          <v-list-item v-for="(item, i) in toothModel[ind]" :key="i">
            <v-list-item-content>
              <v-list-item-title
                v-if="item.isDiagnose"
                v-text="item.treatmentName"
                class="red--text text--lighten-1"
              />
              <v-list-item-title v-else v-text="item.treatmentName" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list> -->
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["treatmentMapping"],

  data() {
    return {
      selectedTooth: 0,

      tooths: [
        {
          ref: 1,
          style: "top: 36.1%; left: 45px; width: 59px; position: absolute",
          src: require("@/assets/teeth/Asset 73@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 2,
          style: "top: 31%; left: 49.6px; width: 57px; position: absolute",
          src: require("@/assets/teeth/Asset 72@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 3,
          style: "top: 25.7%; left: 57px; width: 57px; position: absolute",
          src: require("@/assets/teeth/Asset 71@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 4,
          style: "top: 21.3%; left: 67.5px; width: 52px; position: absolute",
          src: require("@/assets/teeth/Asset 70@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 5,
          style: "top: 16.8%; left: 78px; width: 50px; position: absolute",
          src: require("@/assets/teeth/Asset 69@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 6,
          style: "top: 11.3%; left: 98px; width: 46px; position: absolute",
          src: require("@/assets/teeth/Asset 68@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 7,
          style: "top: 7.9%; left: 132px; width: 33px; position: absolute",
          src: require("@/assets/teeth/Asset 67@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 8,
          style: "top: 4.8%; left: 161px; width: 34px; position: absolute",
          src: require("@/assets/teeth/Asset 66@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 9,
          style: "top: 5.2%; left: 193px; width: 33px; position: absolute",
          src: require("@/assets/teeth/Asset 65@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 10,
          style: "top: 7.6%; left: 222px; width: 39px; position: absolute",
          src: require("@/assets/teeth/Asset 64@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 11,
          style: "top: 11.4%; right: 107px; width: 51px; position: absolute",
          src: require("@/assets/teeth/Asset 63@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 12,
          style: "top: 16.3%; right: 82px; width: 59px; position: absolute",
          src: require("@/assets/teeth/Asset 62@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 13,
          style: "top: 21%; right: 74px; width: 58px; position: absolute",
          src: require("@/assets/teeth/Asset 61@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 14,
          style: "top: 25.3%; right: 67px; width: 59px; position: absolute",
          src: require("@/assets/teeth/Asset 60@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 15,
          style: "top: 30.6%; right: 57px; width: 64px; position: absolute",
          src: require("@/assets/teeth/Asset 59@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 16,
          style: "top: 36.2%; right: 55px; width: 64px; position: absolute",
          src: require("@/assets/teeth/Asset 58@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 17,
          style: "bottom: 38.8%; right: 54px; width: 65px; position: absolute",
          src: require("@/assets/teeth/Asset 57@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 18,
          style: "bottom: 33.3%; right: 57px; width: 63px; position: absolute",
          src: require("@/assets/teeth/Asset 56@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 19,
          style: "bottom: 27.7%; right: 63px; width: 62px; position: absolute",
          src: require("@/assets/teeth/Asset 55@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 20,
          style: "bottom: 23.3%; right: 72px; width: 60px; position: absolute",
          src: require("@/assets/teeth/Asset 54@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 21,
          style: "bottom: 18%; right: 83px; width: 57px; position: absolute",
          src: require("@/assets/teeth/Asset 53@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 22,
          style: "bottom: 13.5%; right: 107px; width: 49px; position: absolute",
          src: require("@/assets/teeth/Asset 52@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 23,
          style: "bottom: 8.4%; right: 142px; width: 36px; position: absolute",
          src: require("@/assets/teeth/Asset 51@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 24,
          style: "bottom: 6.3%; right: 174px; width: 32px; position: absolute",
          src: require("@/assets/teeth/Asset 50@4x.png"),
          transition: "slide-y-transition",
        },
        {
          ref: 25,
          style: "bottom: 6.7%; left: 164px; width: 31px; position: absolute",
          src: require("@/assets/teeth/Asset 49@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 26,
          style: "bottom: 8.7%; left: 131px; width: 37px; position: absolute",
          src: require("@/assets/teeth/Asset 48@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 27,
          style: "bottom: 12.9%; left: 94px; width: 53px; position: absolute",
          src: require("@/assets/teeth/Asset 47@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 28,
          style: "bottom: 17.7%; left: 59px; width: 70px; position: absolute",
          src: require("@/assets/teeth/Asset 46@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 29,
          style: "bottom: 23.4%; left: 49px; width: 71px; position: absolute",
          src: require("@/assets/teeth/Asset 45@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 30,
          style: "bottom: 28%; left: 40px; width: 73px; position: absolute",
          src: require("@/assets/teeth/Asset 44@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 31,
          style: "bottom: 33.5%; left: 41px; width: 66px; position: absolute",
          src: require("@/assets/teeth/Asset 43@4x.png"),
          transition: "slide-x-transition",
        },
        {
          ref: 32,
          style: "bottom: 38.8%; left: 42px; width: 64px; position: absolute",
          src: require("@/assets/teeth/Asset 42@4x.png"),
          transition: "slide-x-transition",
        },
      ],

      toothModel: [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ],
    };
  },

  watch: {
    treatmentMapping() {
      this.refreshTeeth();
    },
  },
  created() {},

  mounted() {
    this.refreshTeeth();
  },

  methods: {
    clicked(e, s) {
      var el = document.getElementsByClassName("selectedImage")[0];
      if (el) {
        el.classList.remove("selectedImage");
      }
      e.currentTarget.classList.toggle("selectedImage");
      this.selectedTooth = s;
      this.$emit("selectTooth", s);
    },
    refreshTeeth() {
      this.toothModel = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      if (this.treatmentMapping) {
        for (var i = 0; i < this.treatmentMapping.length; i++) {
          var treat = this.treatmentMapping[i];
          var e = treat.treatmentValue;

          var ref = this.$refs[e];

          if (ref) {
            ref[0].$el.classList.add("selectedTreatment");
            this.toothModel[parseInt(e) - 1].push(treat);
          }
        }
      }
    },
  },
};
</script>

<style>
.image {
  position: relative;
  width: 400px;
  height: 600px;
  background-image: url(../assets/teeth/Asset78@4x.png);
  background-position: center center;
  background-size: contain;
  margin-top: -50px;
}

.imageDark {
  background-image: url(../assets/teeth/Asset78@4xdark.png) !important;
}

.v-image {
  cursor: pointer;
}

.image > div:hover {
  filter: invert(0.7);
  transform: scale(1.2);
  z-index: 3;
}

.selectedImage {
  filter: invert(0.7) !important;
  transform: scale(1.3);
  z-index: 3;
}

.selectedTreatment {
  filter: hue-rotate(90deg);
  transform: scale(1.1);
  z-index: 3;
}

.selectedTreatment:hover {
  filter: hue-rotate(90deg) !important;
  transform: scale(1.2);
  z-index: 3;
}
</style>
