<template>
  <v-card flat width="100%">
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="guid"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            :label="$t('search')"
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-switch
            v-model="onlyThis"
            :label="$t('procedures.onlyThisSession')"
            class="mt-5"
          ></v-switch>
          <confirm-dialog
            :openDialog="dialogDelete"
            :onClicked="deleteItemConfirm"
            :onClose="closeDelete"
          ></confirm-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div style="width: 80px" class="text-end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.dateReg`]="{ item }">
        {{ item.dateReg | moment("YYYY/MM/DD") }}
      </template>

      <template v-slot:[`item.initialDiagnosis`]="{ item }">
        <v-menu
          v-if="item.initialDiagnosis && item.initialDiagnosis.length > 30"
          :close-on-content-click="false"
          top
          transition="scale-transition"
          width=""
          :min-width="$vuetify.breakpoint.xsOnly ? '84%' : '450'"
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="" v-bind="attrs" v-on="on">
              {{ item.initialDiagnosis.substring(25, 1) + " ..." }}
            </v-chip>
          </template>

          <v-card>
            <v-card-text>
              <v-textarea
                autocomplete="email"
                readonly
                hide-details=""
                :value="item.initialDiagnosis"
                :label="$t('patientHistory.initialDiagnosis')"
              />
            </v-card-text>
          </v-card>
        </v-menu>
        <span v-else>
          {{ item.initialDiagnosis }}
        </span>
      </template>

      <template v-slot:[`item.finalDiagnosis`]="{ item }">
        <v-menu
          v-if="item.finalDiagnosis && item.finalDiagnosis.length > 30"
          :close-on-content-click="false"
          top
          transition="scale-transition"
          width=""
          :min-width="$vuetify.breakpoint.xsOnly ? '84%' : '450'"
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="" v-bind="attrs" v-on="on">
              {{ item.finalDiagnosis.substring(25, 1) + " ..." }}
            </v-chip>
          </template>

          <v-card>
            <v-card-text>
              <v-textarea
                autocomplete="email"
                readonly
                hide-details=""
                :value="item.finalDiagnosis"
                :label="$t('patientHistory.finalDiagnosis')"
              />
            </v-card-text>
          </v-card>
        </v-menu>
        <span v-else>
          {{ item.finalDiagnosis }}
        </span>
      </template>
      <template v-slot:[`item.chiefComplaintAndDuration`]="{ item }">
        <v-menu
          v-if="
            item.chiefComplaintAndDuration &&
            item.chiefComplaintAndDuration.length > 30
          "
          :close-on-content-click="false"
          top
          transition="scale-transition"
          width=""
          :min-width="$vuetify.breakpoint.xsOnly ? '84%' : '450'"
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="" v-bind="attrs" v-on="on">
              {{ item.chiefComplaintAndDuration.substring(25, 1) + " ..." }}
            </v-chip>
          </template>

          <v-card>
            <v-card-text>
              <v-textarea
                autocomplete="email"
                readonly
                hide-details=""
                :value="item.chiefComplaintAndDuration"
                :label="$t('patientHistory.chiefComplaintAndDuration')"
              />
            </v-card-text>
          </v-card>
        </v-menu>
        <span v-else>
          {{ item.chiefComplaintAndDuration }}
        </span>
      </template>
      <template v-slot:[`item.drugsHistoryAndDrugsAllergy`]="{ item }">
        <v-menu
          v-if="
            item.drugsHistoryAndDrugsAllergy &&
            item.drugsHistoryAndDrugsAllergy.length > 30
          "
          :close-on-content-click="false"
          top
          transition="scale-transition"
          width=""
          :min-width="$vuetify.breakpoint.xsOnly ? '84%' : '450'"
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="" v-bind="attrs" v-on="on">
              {{ item.drugsHistoryAndDrugsAllergy.substring(25, 1) + " ..." }}
            </v-chip>
          </template>

          <v-card>
            <v-card-text>
              <v-textarea
                autocomplete="email"
                readonly
                hide-details=""
                :value="item.drugsHistoryAndDrugsAllergy"
                :label="$t('patientHistory.drugsHistoryAndDrugsAllergy')"
              />
            </v-card-text>
          </v-card>
        </v-menu>
        <span v-else>
          {{ item.drugsHistoryAndDrugsAllergy }}
        </span>
      </template>

      <template v-slot:[`item.historyOfPresentIllness`]="{ item }">
        <v-menu
          v-if="
            item.historyOfPresentIllness &&
            item.historyOfPresentIllness.length > 30
          "
          :close-on-content-click="false"
          top
          transition="scale-transition"
          width=""
          :min-width="$vuetify.breakpoint.xsOnly ? '84%' : '450'"
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="" v-bind="attrs" v-on="on">
              {{ item.historyOfPresentIllness.substring(25, 1) + " ..." }}
            </v-chip>
          </template>

          <v-card>
            <v-card-text>
              <v-textarea
                autocomplete="email"
                readonly
                hide-details=""
                :value="item.historyOfPresentIllness"
                :label="$t('patientHistory.historyOfPresentIllness')"
              />
            </v-card-text>
          </v-card>
        </v-menu>
        <span v-else>
          {{ item.historyOfPresentIllness }}
        </span>
      </template>

      <template v-slot:[`item.medicalAndSurgicalHistory`]="{ item }">
        <v-menu
          v-if="
            item.medicalAndSurgicalHistory &&
            item.medicalAndSurgicalHistory.length > 30
          "
          :close-on-content-click="false"
          top
          transition="scale-transition"
          width=""
          :min-width="$vuetify.breakpoint.xsOnly ? '84%' : '450'"
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="" v-bind="attrs" v-on="on">
              {{ item.medicalAndSurgicalHistory.substring(25, 1) + " ..." }}
            </v-chip>
          </template>

          <v-card>
            <v-card-text>
              <v-textarea
                autocomplete="email"
                readonly
                hide-details=""
                :value="item.medicalAndSurgicalHistory"
                :label="$t('patientHistory.medicalAndSurgicalHistory')"
              />
            </v-card-text>
          </v-card>
        </v-menu>
        <span v-else>
          {{ item.medicalAndSurgicalHistory }}
        </span>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        <v-menu
          v-if="item.note && item.note.length > 30"
          :close-on-content-click="false"
          top
          transition="scale-transition"
          width=""
          :min-width="$vuetify.breakpoint.xsOnly ? '84%' : '450'"
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="" v-bind="attrs" v-on="on">
              {{ item.note.substring(25, 1) + " ..." }}
            </v-chip>
          </template>

          <v-card>
            <v-card-text>
              <v-textarea
                autocomplete="email"
                readonly
                hide-details=""
                :value="item.note"
                :label="$t('notes')"
              />
            </v-card-text>
          </v-card>
        </v-menu>
        <span v-else>
          {{ item.note }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog },
  props: ["guid", "refreshPatientHistoryTable"],
  data() {
    return {
      search: "",
      onlyThis: true,
      dialogDelete: false,
      file: null,
      headers: [
        {
          text: this.$t("patientHistory.initialDiagnosis"),
          value: "initialDiagnosis",
        },
        {
          text: this.$t("patientHistory.finalDiagnosis"),
          value: "finalDiagnosis",
        },
        {
          text: this.$t("patientHistory.chiefComplaintAndDuration"),
          value: "chiefComplaintAndDuration",
        },
        {
          text: this.$t("patientHistory.drugsHistoryAndDrugsAllergy"),
          value: "drugsHistoryAndDrugsAllergy",
        },
        {
          text: this.$t("patientHistory.historyOfPresentIllness"),
          value: "historyOfPresentIllness",
        },
        {
          text: this.$t("patientHistory.medicalAndSurgicalHistory"),
          value: "medicalAndSurgicalHistory",
        },
        { text: this.$t("notes"), value: "note" },
        { text: this.$t("date"), value: "dateReg" },
        { text: "", value: "actions" },
      ],

      items: [],
      editedItem: {},
    };
  },
  watch: {
    onlyThis() {
      this.refreshTable();
    },
    refreshPatientHistoryTable() {
      this.refreshTable();
    },
  },
  created() {
    this.refreshTable();
  },

  methods: {
    editItem(item) {
      this.$emit("editPatientHistory", item);
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    printItem(item) {
      this.$axios({
        url: "Prescription/Print?guid=" + item.collectionGuid,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },

    deleteItemConfirm() {
      this.$axios
        .delete("PatientHistory/Delete?guid=" + this.editedItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          this.refreshTable(this.currentTab());
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    refreshTable() {
      this.$axios
        .get(`PatientHistory/Get?guid=${this.guid}&&onlyThis=${this.onlyThis}`)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
