<template>
  <v-container fluid>
    <confirm-dialog
      :openDialog="dialogSessionEnd"
      :onClicked="saveNewSession"
      :onClose="
        () => {
          dialogSessionEnd = false;
        }
      "
      :dialogTitle="$t('patients.sessionEnd')"
    ></confirm-dialog>

    <patient-detail :patientGuid="patient.patientGuid"> </patient-detail>

    <v-expansion-panels flat multiple v-model="panel" class="mt-4">
      <v-expansion-panel>
        <v-card outlined>
          <v-expansion-panel-header>
            <v-tabs
              v-model="tab"
              :style="
                $vuetify.rtl
                  ? 'padding-left: 15px;margin-right: -24px;'
                  : 'padding-right: 15px;margin-left: -24px;'
              "
            >
              <v-tab
                v-for="item in tabs.filter((m) => m.auth)"
                :key="item.value"
                @click.stop="tabClick(item.value)"
              >
                <v-icon class="mx-1"> {{ item.icon }}</v-icon>

                {{ $t(item.text) }}
              </v-tab>
            </v-tabs>
          </v-expansion-panel-header>
          <v-divider class="" />

          <v-expansion-panel-content>
            <v-tabs-items v-model="tab">
              <v-tab-item v-if="tabs[0].auth">
                <v-card flat>
                  <v-card-text class="px-0 pt-7">
                    <v-row no-gutters>
                      <v-col>
                        <v-form ref="form0" v-model="valid0" lazy-validation>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete
                                :items="services"
                                item-text="service.serviceName"
                                item-value="guid"
                                v-model="generalServiceGuid"
                                :label="$t('services.serviceName')"
                                :rules="rules"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="generalModel.servicePrice"
                                :label="$t('services.servicePrice')"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="generalModel.quantity"
                                type="number"
                                min="1"
                                :label="$t('procedures.quantity')"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                readonly
                                :value="generalModel.totalAmmount"
                                :label="$t('procedures.totalAmount')"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-textarea
                                auto-grow
                                v-model="dentalModel.note"
                                :label="$t('notes')"
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-switch
                                v-model="generalModel.isDiagnose"
                                :label="$t('procedures.isDiagnose')"
                                :hint="$t('procedures.isDiagnoseHint')"
                                persistent-hint
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-form>
                        <v-card-actions>
                          <v-btn
                            color="green darken-1"
                            class="white--text"
                            @click="save"
                            :disabled="!valid0"
                            :min-width="100"
                          >
                            <v-icon>mdi-content-save-outline</v-icon>
                            {{ $t("save") }}
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn to="/patientschedule" color="" text>
                            {{ $t("backToList") }}
                          </v-btn>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item v-if="tabs[1].auth">
                <v-card flat>
                  <v-card-text class="px-0 pt-7">
                    <v-row no-gutters>
                      <v-col>
                        <v-form ref="form1" v-model="valid1" lazy-validation>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete
                                :items="services"
                                item-text="service.serviceName"
                                item-value="guid"
                                v-model="dentalServiceGuid"
                                :label="$t('services.serviceName')"
                                :rules="rules"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="dentalModel.servicePrice"
                                :label="$t('services.servicePrice')"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="dentalModel.quantity"
                                type="number"
                                min="1"
                                :label="$t('procedures.quantity')"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                readonly
                                :value="dentalModel.totalAmmount"
                                :label="$t('procedures.totalAmount')"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-switch
                                v-model="dentalModel.isDiagnose"
                                :label="$t('procedures.isDiagnose')"
                                :hint="$t('procedures.isDiagnoseHint')"
                                persistent-hint
                              ></v-switch>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                readonly
                                v-model="dentalModel.treatmentValue"
                                :label="$t('procedures.selectedTooth')"
                                append-icon="mdi-close"
                                @click:append="removeSelectedTooth"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="12">
                              <v-textarea
                                auto-grow
                                v-model="dentalModel.note"
                                :label="$t('notes')"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-form>

                        <v-card-actions>
                          <v-btn
                            color="green darken-1"
                            class="white--text"
                            @click="save"
                            :disabled="!valid1"
                            :min-width="100"
                          >
                            <v-icon>mdi-content-save-outline</v-icon>
                            {{ $t("save") }}
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn to="/patientschedule" color="" text>
                            {{ $t("backToList") }}
                          </v-btn>
                        </v-card-actions>
                      </v-col>
                      <v-col cols="auto" class="py-2">
                        <teeth
                          @selectTooth="selectToothChange"
                          :treatmentMapping="this.patient.treatmentMapping"
                        ></teeth>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item v-if="tabs[2].auth">
                <v-card flat>
                  <v-card-text class="px-0 pt-7">
                    <v-row no-gutters>
                      <v-col>
                        <v-form ref="form2" v-model="valid2" lazy-validation>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete
                                :items="services"
                                item-text="service.serviceName"
                                item-value="guid"
                                v-model="operationServiceGuid"
                                :label="$t('services.serviceName')"
                                :rules="rules"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="operationModel.servicePrice"
                                :label="$t('services.servicePrice')"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="operationModel.quantity"
                                type="number"
                                min="1"
                                :label="$t('procedures.quantity')"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                readonly
                                :value="operationModel.totalAmmount"
                                :label="$t('procedures.totalAmount')"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-textarea
                                auto-grow
                                v-model="dentalModel.note"
                                :label="$t('notes')"
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-switch
                                v-model="operationModel.isDiagnose"
                                :label="$t('procedures.isDiagnose')"
                                :hint="$t('procedures.isDiagnoseHint')"
                                persistent-hint
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-form>
                        <v-card-actions>
                          <v-btn
                            color="green darken-1"
                            class="white--text"
                            @click="save"
                            :disabled="!valid2"
                            :min-width="100"
                          >
                            <v-icon>mdi-content-save-outline</v-icon>
                            {{ $t("save") }}
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn to="/patientschedule" color="" text>
                            {{ $t("backToList") }}
                          </v-btn>
                        </v-card-actions>
                      </v-col>
                      <!-- <v-col cols="auto">
                        <div style="min-width: 400px"></div>
                      </v-col> -->
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item v-if="tabs[3].auth">
                <add-prescription
                  :guid="this.$route.params.guid"
                  :editPrescriptionModel="editPrescriptionModel"
                  @savePresecription="savePresecription"
                ></add-prescription>
              </v-tab-item>

              <v-tab-item>
                <add-patient-history
                  :patientGuid="patient.patientGuid"
                  :editPatientHistoryModel="editPatientHistoryModel"
                  @savePatientHistory="savePatientHistory"
                ></add-patient-history>
              </v-tab-item>

              <v-tab-item>
                <file-manager
                  v-if="patient && patient.patientGuid"
                  :guid="patient.patientGuid"
                />
              </v-tab-item>

              <v-tab-item>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead></thead>

                        <tbody v-if="billDetails">
                          <tr>
                            <td>{{ $t("procedures.totalAmount") }}</td>
                            <td>{{ billDetails.totalAmount }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("bills.discount") }}</td>
                            <td>{{ billDetails.discount }}</td>
                          </tr>
                          <tr class="green--text text--darken">
                            <td>{{ $t("bills.paid") }}</td>
                            <td>{{ billDetails.paid }}</td>
                          </tr>
                          <tr class="red--text text--darken">
                            <td>{{ $t("bills.remainingAmount") }}</td>
                            <td>{{ billDetails.remainingAmount }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-expansion-panel-content>
        </v-card>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card
      outlined
      transition="slide-y-transition"
      class="mt-4"
      v-if="currentTab() != 4 && currentTab() != 6"
    >
      <div v-if="currentTab() == 3">
        <prescription-master-detail
          :guid="this.$route.params.guid"
          :refreshPrescriptionTable="refreshPrescriptionTable"
          @deletePresecription="deletePresecription"
          @editPrescription="editPrescription"
        >
        </prescription-master-detail>
      </div>

      <div v-else-if="currentTab() == 5">
        <patient-history-table
          :guid="this.$route.params.guid"
          :refreshPatientHistoryTable="refreshPatientHistoryTable"
          @editPatientHistory="editPatientHistory"
        ></patient-history-table>
      </div>
      <v-row v-else-if="currentTab() == 4 || currentTab() == 6"> </v-row>

      <v-data-table
        v-else
        :headers="headers"
        :items="procedures"
        :search="search"
        :items-per-page="5"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-switch
              v-model="onlyThis"
              class="mt-8 mr-2"
              :label="$t('procedures.onlyThisSession')"
            >
            </v-switch>

            <confirm-dialog
              :openDialog="dialog"
              :onClicked="treatmentItemConfirm"
              :onClose="close"
              :dialogTitle="$t('patients.treatment')"
            ></confirm-dialog>

            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top v-if="item.isDiagnose && isInRole('48')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="cyan"
                  @click="treatmentItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-pill </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("patients.treatment") }}</span>
            </v-tooltip>

            <v-tooltip
              top
              v-if="isInRole('47') && doctorGuid == item.doctorGuid"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red darken-2"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.totalAmmount`]="{ item }">
          {{ item.servicePrice * item.serviceQty }}
        </template>
        <template v-slot:[`item.dateReg`]="{ item }">
          {{ item.dateReg | moment("YYYY/MM/DD hh:mm A") }}
        </template>
        <template v-slot:[`item.isDiagnose`]="{ item }">
          <div class="text-center">
            <v-switch disabled v-model="item.isDiagnose"></v-switch>
          </div>
        </template>

        <template v-slot:[`item.note`]="{ item }">
          <v-menu
            v-if="item.note && item.note.length > 30"
            :close-on-content-click="false"
            top
            transition="scale-transition"
            width=""
            :min-width="$vuetify.breakpoint.xsOnly ? '84%' : '450'"
            :offset-y="true"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="" v-bind="attrs" v-on="on">
                {{ item.note.substring(25, 1) + " ..." }}
              </v-chip>
            </template>

            <v-card>
              <v-card-text>
                <v-textarea
                  autocomplete="email"
                  readonly
                  hide-details=""
                  :value="item.note"
                  :label="$t('notes')"
                />
              </v-card-text>
            </v-card>
          </v-menu>
          <span v-else>
            {{ item.note }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Teeth from "@/components/Teeth.vue";
import PatientDetail from "@/components/PatientDetail.vue";
import AddPrescription from "../Prescription/AddPrescription.vue";
import PrescriptionMasterDetail from "../Prescription/PrescriptionMasterDetail.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import AddPatientHistory from "../PatientHistory/AddPatientHistory.vue";
import PatientHistoryTable from "../PatientHistory/PatientHistoryTable.vue";
import FileManager from "@/components/FileManager.vue";
export default {
  components: {
    Teeth,
    PatientDetail,
    AddPrescription,
    PrescriptionMasterDetail,
    ConfirmDialog,
    AddPatientHistory,
    PatientHistoryTable,
    FileManager,
  },
  data() {
    return {
      valid0: false,
      valid1: false,
      valid2: false,
      panel: [0],
      tab: null,
      dialog: false,
      dialogDelete: false,
      dialogSessionEnd: false,
      onlyThis: true,
      editedIndex: -1,
      search: "",
      procedures: [],
      prescriptions: [],
      refreshPrescriptionTable: 0,
      refreshPatientHistoryTable: 0,
      headers: [
        { text: this.$t("services.serviceName"), value: "serviceName" },
        { text: this.$t("services.servicePrice"), value: "servicePrice" },
        { text: this.$t("procedures.quantity"), value: "serviceQty" },
        { text: this.$t("procedures.totalAmount"), value: "totalAmmount" },
        { text: this.$t("doctors.doctorName"), value: "doctorName" },
        { text: this.$t("date"), value: "dateReg" },
        { text: this.$t("notes"), value: "note" },
        { text: this.$t("procedures.isDiagnose"), value: "isDiagnose" },
        { text: "", value: "actions" },
      ],
      patient: {},
      procedureModel: {},
      generalServiceGuid: null,
      dentalServiceGuid: null,
      operationServiceGuid: null,
      generalModel: {
        serviceGuid: null,
        service: {},
        treatmentValue: "",
        appointmentGuid: this.$route.params.guid,
        quantity: 1,
        servicePrice: 0,
        totalAmmount: 0,
        isDiagnose: false,
        note: "",
        type: 0,
      },
      dentalModel: {
        serviceGuid: null,
        service: {},
        treatmentValue: "",
        appointmentGuid: this.$route.params.guid,
        quantity: 1,
        servicePrice: 0,
        totalAmmount: 0,
        isDiagnose: false,
        note: "",
        type: 1,
      },
      operationModel: {
        serviceGuid: null,
        service: {},
        treatmentValue: "",
        appointmentGuid: this.$route.params.guid,
        quantity: 1,
        servicePrice: 0,
        totalAmmount: 0,
        isDiagnose: false,
        note: "",
        type: 2,
      },

      tabs: [
        {
          text: "services.serviceGroupType.general",
          value: 0,
          icon: "mdi-bottle-tonic-plus-outline",
          auth: this.isInRole("65"),
        },
        {
          text: "services.serviceGroupType.dental",
          value: 1,
          icon: "mdi-tooth-outline",
          auth: this.isInRole("66"),
        },
        {
          text: "services.serviceGroupType.operation",
          value: 2,
          icon: "mdi-bed-outline",
          auth: this.isInRole("67"),
        },
        {
          text: "prescriptions.prescriptions",
          value: 3,
          icon: "mdi-prescription",
          auth: this.isInRole("32"),
        },
        {
          text: "patientHistory.patientHistory",
          value: 5,
          icon: "mdi-card-account-details-outline",
          auth: true,
        },
        {
          text: "patients.files",
          value: 6,
          icon: "mdi-card-account-details-outline",
          auth: true,
        },
        {
          text: "bills.paidAmounts",
          value: 4,
          icon: "mdi-file-document-edit-outline",
          auth: this.isInRole("24"),
        },
      ],

      services: [],
      editedItem: {
        branchCode: "",
        branchName: "",
        branchUrl: "",
      },

      defaultItem: {
        branchCode: "",
        branchName: "",
        branchUrl: "",
      },

      billDetails: null,
      rules: [
        (value) => (!!value && value != "") || this.$t("ThisFieldIsRequired"),
      ],
      //predicate: [],
      editCollectionGuid: null,
      editPatientHistoryModel: {},
      editPrescriptionModel: {},
    };
  },
  created() {
    this.tab = 0;
    this.refreshServices(this.currentTab());
  },
  mounted() {},
  watch: {
    generalServiceGuid(newVal) {
      if (newVal) {
        var service = this.services.filter((m) => m.guid == newVal)[0];
        if (service) {
          this.generalModel.serviceGuid = service.serviceGuid;
          this.generalModel.servicePrice = service.servicePrice;
          this.generalModel.totalAmmount =
            service.servicePrice * this.generalModel.quantity;
        }
      }
    },

    "generalModel.quantity": {
      handler: function (newVal) {
        if (newVal) {
          this.generalModel.totalAmmount =
            this.generalModel.servicePrice * newVal;
        }
      },
      deep: true,
    },

    "generalModel.servicePrice": {
      handler: function (newVal) {
        if (newVal) {
          this.generalModel.totalAmmount = this.generalModel.quantity * newVal;
        }
      },
      deep: true,
    },

    dentalServiceGuid(newVal) {
      if (newVal) {
        var service = this.services.filter((m) => m.guid == newVal)[0];
        if (service) {
          this.dentalModel.serviceGuid = service.serviceGuid;
          this.dentalModel.servicePrice = service.servicePrice;
          this.dentalModel.totalAmmount =
            service.servicePrice * this.dentalModel.quantity;
        }
      }
    },

    "dentalModel.quantity": {
      handler: function (newVal) {
        if (newVal) {
          this.dentalModel.totalAmmount =
            this.dentalModel.servicePrice * newVal;
        }
      },
      deep: true,
    },

    "dentalModel.servicePrice": {
      handler: function (newVal) {
        if (newVal) {
          this.dentalModel.totalAmmount = this.dentalModel.quantity * newVal;
        }
      },
      deep: true,
    },

    operationServiceGuid(newVal) {
      if (newVal) {
        var service = this.services.filter((m) => m.guid == newVal)[0];
        if (service) {
          this.operationModel.serviceGuid = service.serviceGuid;
          this.operationModel.servicePrice = service.servicePrice;
          this.operationModel.totalAmmount =
            service.servicePrice * this.operationModel.quantity;
        }
      }
    },

    "operationModel.quantity": {
      handler: function (newVal) {
        if (newVal) {
          this.operationModel.totalAmmount =
            this.operationModel.servicePrice * newVal;
        }
      },
      deep: true,
    },

    "operationModel.servicePrice": {
      handler: function (newVal) {
        if (newVal) {
          this.operationModel.totalAmmount =
            this.operationModel.quantity * newVal;
        }
      },
      deep: true,
    },

    tab() {
      this.refreshServices(this.currentTab());
    },

    onlyThis() {
      this.refreshTable(this.currentTab());
    },
  },
  computed: {},
  methods: {
    selectToothChange(s) {
      this.dentalModel.treatmentValue = s;
    },
    removeSelectedTooth() {
      this.dentalModel.treatmentValue = null;

      var el = document.getElementsByClassName("selectedImage")[0];
      if (el) {
        el.classList.remove("selectedImage");
      }
    },
    tabClick() {},
    refreshServices(i) {
      this.refreshTable(i);

      if (i == 3 || i == 4 || i == 5) {
        return;
      }
      this.$axios
        .get("ServiceCharge/GetByDoctor?type=" + i)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.services = response.data.data;
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        });
    },
    refreshTable(i) {
      if (i == 3) {
        this.refreshPrescriptionTable += 1;
        return;
      } else if (i == 5) {
        this.refreshPatientHistoryTable += 1;
        return;
      } else if (i == 4) {
        if (this.patient.billGuid) {
          this.$axios
            .get(`Bill/Get?guid=${this.patient.billGuid}`)
            .then((response) => {
              var discount = response.data.data.bill.totalDiscount;
              var paid = response.data.data.totalPaid;

              var totalAmount = response.data.data.bodies.reduce(
                (a, b) => a + (b["amount"] * b["quantity"] || 0),
                0
              );

              var remainingAmount = totalAmount - discount - paid;
              this.billDetails = {
                discount,
                paid,
                totalAmount,
                remainingAmount,
              };
            });
        }
      } else {
        this.$axios
          .get(
            `Procedure/GetByType?guid=${this.$route.params.guid}&type=${i}&onlyThis=${this.onlyThis}`
          )
          .then((response) => {
            this.procedures = response.data.data.procedures;
            this.patient = response.data.data;
            // console.log(this.patient);
          });
      }
    },

    treatmentItem(item) {
      this.editedIndex = this.procedures.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    treatmentItemConfirm() {
      var treatmentItem = this.procedures[this.editedIndex];

      this.$axios
        .get("Procedure/Treat?guid=" + treatmentItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable(this.currentTab());
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeTreat();
    },

    closeTreat() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItem(item) {
      this.editedIndex = this.procedures.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.procedures[this.editedIndex];

      this.$axios
        .delete("Procedure/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable(this.currentTab());
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.formTable.reset();
      this.$refs.formTable.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var val = false;
      if (this.currentTab() == 0) {
        val = this.$refs.form0.validate();
        this.procedureModel = { ...this.generalModel };
      } else if (this.currentTab() == 1) {
        val = this.$refs.form1.validate();
        this.procedureModel = { ...this.dentalModel };
      } else if (this.currentTab() == 2) {
        val = this.$refs.form2.validate();
        this.procedureModel = { ...this.operationModel };
      }

      if (val) {
        this.$axios
          .post("Procedure/Add", this.procedureModel)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              this.refreshTable(this.currentTab());
              this.removeSelectedTooth();
              this.resetData();
            } else if (response.data.status == "SessionEnd") {
              this.dialogSessionEnd = true;
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
              this.refreshTable(this.currentTab());
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          });
      }
    },

    saveNewSession() {
      var val = false;
      if (this.currentTab() == 0) {
        val = this.$refs.form0.validate();
        this.procedureModel = { ...this.generalModel };
      } else if (this.currentTab() == 1) {
        val = this.$refs.form1.validate();
        this.procedureModel = { ...this.dentalModel };
      } else if (this.currentTab() == 2) {
        val = this.$refs.form2.validate();
        this.procedureModel = { ...this.operationModel };
      }
      if (val) {
        var data = {
          serviceGuid: this.procedureModel.serviceGuid,
          treatmentValue: this.procedureModel.treatmentValue,
          appointmentGuid: this.procedureModel.appointmentGuid,
          quantity: this.procedureModel.quantity,
          isDiagnose: this.procedureModel.isDiagnose,
          type: this.procedureModel.type,
          isNewSession: true,
        };

        this.$axios
          .post("Procedure/Add", data)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              this.refreshTable(this.currentTab());
              this.resetData();

              // window.location.href = "/patienttreatment/" + response.data.data;
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
              this.refreshTable(this.currentTab());
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          });
        this.dialogSessionEnd = false;
      }
    },

    deletePresecription(response) {
      if (response.data.status == "Successful") {
        this.$toast(this.$t("operationAccomplishedSuccessfully"));
      } else {
        this.$toast.error(this.$t("error." + response.data.message));
      }
    },

    editPrescription(item) {
      this.editPrescriptionModel = item;
    },

    savePresecription(response) {
      if (response.data.status == "Successful") {
        this.$toast(this.$t("operationAccomplishedSuccessfully"));
      } else {
        this.$toast.error(this.$t("error." + response.data.message));
      }

      this.refreshTable(this.currentTab());
    },

    savePatientHistory() {
      this.refreshTable(this.currentTab());
    },

    currentTab() {
      return this.tabs.filter((m) => m.auth)[this.tab].value;
    },
    resetData() {
      this.procedureModel.serviceGuid = null;
      this.procedureModel.service = {};
      this.procedureModel.treatmentValue = "";
      this.procedureModel.quantity = 1;
      this.procedureModel.servicePrice = 0;
      this.procedureModel.totalAmmount = 0;
      this.procedureModel.isDiagnose = 0;
      this.procedureModel.note = "";
      this.procedureModel.type = this.currentTab();

      if (this.currentTab() == 0) {
        this.generalModel = { ...this.procedureModel };
        this.generalServiceGuid = null;
        this.$refs.form0.resetValidation();
      } else if (this.currentTab() == 1) {
        this.dentalModel = { ...this.procedureModel };
        this.dentalServiceGuid = null;
        this.$refs.form1.resetValidation();
      } else if (this.currentTab() == 2) {
        this.operationModel = { ...this.procedureModel };
        this.operationServiceGuid = null;
        this.$refs.form2.resetValidation();
      }
    },
    editPatientHistory(model) {
      this.editPatientHistoryModel = model;
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

/* .v-tab {
  margin: 0 !important;
}

.v-slide-group__prev {
  display: none !important;
} */
</style>
